import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/expertstyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";

import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";
import { setExpertMode } from '../lib/localStorage';
import { EXPERT_MODE } from '../constants';
export default function Expertsmodal(props) {

  const dispatch =  useDispatch()
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Expert Mode</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.pinkBox}`}>

            <TiInfo className={`${cascading.pinktriangle}`} />
            <p className='mb-1'>
              Expert mode turns off the 'Confirm' transaction prompt, and allows high slippage trades that often result in bad rates and lost funds.
            </p>

          </div>

          <p className={`${cascading.innerheadwhite}`}>Only use this mode if you know what you’re doing.
          </p>

          <div className="form-check mt-4 mb-4">
            <input className={`form-check-input ${cascading.checkboxstyle}`} type="checkbox" value="" id="flexCheckDefault" />
            <label className={`form-check-label mb-0 mt-0 ${cascading.innerheadwhite}`} for="flexCheckDefault">
              Don’t show this again
            </label>
          </div>
          <button 
          className={`btn ${cascading.connectwalletbtn}`} 
          type='button' 
          onClick={()=>{
            setExpertMode(true)
            dispatch({
              type:EXPERT_MODE,
              payload:true
            })
            props.onHide()
          }}>
            Turn on Expert Mode
          </button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={()=>{props.onHide()}}>Cancel</button>






        </Modal.Body>

      </Modal>
    </div>
  )
}

