import React, { useEffect, useState } from 'react'
import { Modal, Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import cascading from '../assests/css/tokenstyle.module.css';
import bnblogo from '../assests/images/bnb.png';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import Walletmodal from './walletmodal';

//lib
import { isEmpty } from "../lib/isEmpty";
import { getWalletAddress, walletControl } from '../lib/localStorage';
import fileObjectUrl from '../lib/img'

//hooks
import { getTokenBalance, GetCurrencyBalance } from '../hooks/useTokens'
import { getTokens } from '../hooks/useTokens'
import {IsCurrency, Significant} from '../hooks/useCommon'


const tokendetail = {
  results: [{
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.666"

  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BNB",
    tokendesc: "BNB",
    balance: "0.0"
  },
  {
    coinimg: bnblogo,
    tokename: "BUSD",
    tokendesc: "BUSD Token",
    balance: "0"
  }]


};
export default function Selecttoken(props) {
  console.log(props, "props")
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setTokens] = useState([])
  const [searchResults, setSearchResults] = useState([]);
  const [activebtn, setActivebtn] = useState("")
  const [balance, setBalance] = useState(0)
  const [walletmodalshow, setWalletmodalshow] = useState(false)
  const [Loading, setLoading] = useState(false)

  const { walletaddress } = useSelector((state) => (state.wallet))

  const handleChange = e => {
    setSearchTerm(e.target.value);
    if (!isEmpty(e.target.value)) {
      let regx = new RegExp(e.target.value, 'i')
      let results = token.filter((val) => {
        return (val?.symbol?.match(regx) || val?.name?.match(regx))
      })
      setSearchResults(results);
    } else {
      //results = getTokens()
      setSearchResults(token);
    }
    // setSearchResults(results);
    console.log(searchResults, "search")
  };



  // useEffect(() => {
  //   console.log("testnetTokens")

  //   let results = []

  //   if (!isEmpty(searchTerm)) {
  //     let regx = new RegExp(searchTerm, 'i')

  //     results = token.filter((val) => {
  //       return (val?.symbol?.match(regx) || val?.name?.match(regx))
  //     })
  //     // results = getTokens().map((val, index) => {
  //     //   val?.symbol?.toLowerCase().includes(searchTerm)
  //     // });
  //     setSearchResults(results);
  //   } else {
  //     results = getTokens()
  //     setSearchResults(token);
  //     // Object.entries(testnetTokens).filter(([token, tokenName],index) =>{
  //     //   console.log("testnetTokens3",token, tokenName)
  //     //   results.push(tokenName)
  //     //   // return tokenName
  //     // }
  //     // );
  //   }
  //   // setSearchResults(results);
  //   console.log(searchResults, "search", results)
  // }, [searchTerm]);


  useEffect(() => {
    if (!isEmpty(getWalletAddress())) {
      setLoading(true)
      TokensBalance()
    }
  }, [walletaddress])

  const TokensBalance = async () => {
    let Token = getTokens()
    let tokens = JSON.stringify(Token)
    tokens = JSON.parse(tokens)
    let calls = []
    let balance = []
    for (let i = 0; i < Token.length; i++) {
      let val = Token[i]
      if (!IsCurrency(val)) {
        console.log(val,'TokensBalance')
        let obj = {
          address: val?.address,
          name: 'balanceOf',
          params: [getWalletAddress()]
        }
        calls.push(obj)
      } else {
        balance[i] = await GetCurrencyBalance()
      }
    }
    // Token.map(async(val, i) => {
    // })
    let balan = await getTokenBalance(calls)
    balance = [...balance, ...balan]
    console.log("balance", balance)
    for (let j = 0; j < tokens.length; j++) {
      tokens[j]['balance'] = balance[j]
      if (j == tokens.length - 1) {
        console.log(tokens, 'Token')
        setSearchResults(tokens)
        setTokens(tokens)
        setLoading(false)
      }
    }
    setBalance(balance)

  }

  const [tokenbtn, setTokenbtn] = useState([{
    img: bnblogo,
    tokenname: "BNB"

  },
  {
    img: bnblogo,
    tokenname: "BNB"

  },
  {
    img: bnblogo,
    tokenname: "BNB"
  },
  {
    img: bnblogo,
    tokenname: "BNB"

  }]);
  return (

    <div className={`${cascading.walletmodalstyle}`}>
      {console.log(props, "checking")}
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Select a Token</h4>
          <button className={`${cascading.closebtn}`} onClick={props.onHide}>X</button>
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          {walletaddress && !isEmpty(walletaddress) ?
            Loading && Loading ?
              <div className={`${cascading.commonsec}`}>
                <p className={`${cascading.labels}`}>Loading...</p>
              </div> :
              <>
                <div className={`${cascading.topsearchsec}`}>
                  <input
                    type="text"
                    placeholder="Search name or paste address"
                    value={searchTerm}
                    onChange={handleChange}
                    className={`${cascading.searchinput}`}
                  />
                </div>
                {props.commontoken &&
                  <div className={`${cascading.commonsec}`}>
                    <p className={`${cascading.labels}`}>Common bases<OverlayTrigger
                      className={`${cascading.tooltip_style}`}
                      key="right"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          These tokens are commonly paired with other tokens.
                        </Tooltip>
                      }
                    >
                      <span className={`mx-2`} ><IoIosHelpCircleOutline fill='#fff' fontSize={18} /></span>
                    </OverlayTrigger></p>
                    <div className={`${cascading.btngrpsec}`}>
                      <ButtonGroup aria-label="Basic example">
                        {searchResults.map((e, i) => (
                          e.commonBase == true ?
                            <Button variant="secondary" id={i} onClick={() => {
                              console.log(e, 'SetToken')
                              let obj = { ...e }
                              setActivebtn(e)
                              // obj['balance'] = balance[i]
                              props.SetToken(obj)
                              props.onHide()
                            }} ><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button>
                            : ""

                        ))}
                      </ButtonGroup>
                    </div>


                    {/* <Button variant="secondary" className={activebtn == i ? `${cascading.activebtn}` : ""} id={i} onClick={() => setActivebtn(i)}><img src={fileObjectUrl(e?.image)} alt='img' className={`img-fluid ${cascading.tokenimgs}`} /> {e?.symbol}</Button> */}


                  </div>
                }


                <ul className={`${cascading.tokenlists}`}>
                  {searchResults.map((e, i) => (
                    <li key={i} onClick={() => {
                      console.log(e, 'SetToken')
                      let obj = { ...e }
                      // obj['balance'] = balance[i]
                      props.SetToken(obj)
                      props.onHide()
                    }}>
                      <div className={`${cascading.tokenrow}`}>
                        <img src={fileObjectUrl(e?.image)} alt='coin' className={`img-fluid ${cascading.timg}`} />
                        <div>
                          <p className={`${cascading.name}`}>{e?.symbol}</p>
                          <p className={`${cascading.desc}`}>{e?.name}</p>
                        </div>
                      </div>
                      <div>
                        {console.log("e.balance",e.balance)}
                        {/* <p className={`${cascading.desc}`}>{isEmpty(balance[i]) ? 0 : parseFloat(balance[i])?.toFixed(2)}</p> */}
                        <p className={`${cascading.desc}`}>{isEmpty(e.balance) ? 0 : parseFloat(e?.balance).toFixed(8)}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </>

            :
            <div className='px-4 py-5'>
              <button
                className={`btn ${cascading.connect_btn}`}
                onClick={() => setWalletmodalshow(true)}>
                Connect Wallet
              </button></div>}

        </Modal.Body>

      </Modal>

      {walletmodalshow && <Walletmodal show={walletmodalshow} onHide={() => setWalletmodalshow(false)} />}

    </div>
  )
}