import React, { useState,useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/confirmsupplystyle.module.css';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import Switch from "react-switch";
import coinimg from '../assests/images/bnb.png'

import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
// import {AddLiquidity,AddLiquidityETH} from "../hooks/useRouter"
import { IoMdClose } from "react-icons/io";
import { ZEROTH_ADDRESS } from '../config/env'

import fileObjectUrl from '../lib/img'
import { toFixedNumber } from '../lib/FixedNumber';

import {roundToSignificant } from '../hooks/useCommon';


export default function ConfirmSupplymodal(props) {

  var propsdata =  props?.supplyObj
  console.log("propsdata",propsdata)

    useEffect(() => {
        console.log("props",props?.supplyObj,props?.pairInfo)
        {console.log("propslpBalance_lp",toFixedNumber(propsdata?.lpBalance),propsdata?.lpBalance)}

    }, [])

    // const liquidity =async()=> {
    //    let result = AddLiquidity()
    // }

    // const liquidityETH =async()=> {
    //     let result = AddLiquidityETH()

    // }


  return (
  
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">

        {props?.pairInfo !==ZEROTH_ADDRESS  ?

        <>
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You will receive</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.balsec}`}>
            <p className={`${cascading.balvalue} ${cascading.brakval}`}>{(roundToSignificant(propsdata?.getLiquidity,2))} <span className={`mx-1`}>
              <img src={fileObjectUrl(propsdata?.fromToken?.image)} alt='coin' className={`img-fluid ${cascading.coinimgstyle}`}/>
              <img src={fileObjectUrl(propsdata?.toToken?.image)} alt='coin' className={`img-fluid ${cascading.coinimgstyle}`}/>

              </span>
               {/* <OverlayTrigger
              className={`${cascading.tooltip_style}`}
              key="left"
              placement="left"
              overlay={
                <Tooltip id={`tooltip-left`}>
                  Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                </Tooltip>
              }
            >
              <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
            </OverlayTrigger> */}
            </p>
            <p className={`${cascading.coiname}`}>{`${propsdata?.fromToken?.symbol}/${propsdata?.toToken?.symbol}`} Pool Tokens</p>

          </div>

          <p className={`${cascading.listtext}`}>Output is estimated. If the price changes by more than 1% your Transactions will revert.</p>
          <div className={`${cascading.listsec}`}>
            <div className={`${cascading.listrow}`}>
              <p>{propsdata?.fromToken?.symbol} Deposited</p>
              <p className={`${cascading.textendsupply}`}><span className={`mx-1 `}>
                <img src={fileObjectUrl(propsdata?.fromToken?.image)} alt='coin' className={`img-fluid  ${cascading.coinimgstyle}`}/>
              </span>{roundToSignificant(propsdata?.fromValue,2)}</p>
            </div>
            <div className={`${cascading.listrow}`}>
              <p>{propsdata?.toToken?.symbol} Deposited</p>
              <p className={`${cascading.textendsupply}`}><span className={`mx-1 `}>
                <img src={fileObjectUrl(propsdata?.toToken?.image)} alt='coin' className={`img-fluid  ${cascading.coinimgstyle}`}/>
              </span>{roundToSignificant(propsdata?.toValue,2)}</p>
              {/* <p>{propsdata?.toValue} */}
                {/* <OverlayTrigger
                className={`${cascading.tooltip_style}`}
                key="left"
                placement="left"
                overlay={
                  <Tooltip id={`tooltip-left`}>
                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                  </Tooltip>
                }
              >
                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
              </OverlayTrigger> */}
              {/* </p> */}
            </div>
            <div className={`${cascading.listrow}`}>
              <p>Rates</p>
              <div>
                <p className={`${cascading.textendsupply}`}>1 {propsdata?.fromToken?.symbol} = {roundToSignificant(propsdata?.toTokenper,6)} {propsdata?.toToken?.symbol}</p>
                <p className={`${cascading.textendsupply}`}>1 {propsdata?.toToken?.symbol} = {roundToSignificant(propsdata?.fromTokenper,6)} {propsdata?.fromToken?.symbol}</p>
              </div>
            </div>
            <div className={`${cascading.listrow}`}>
              <p>Share of Pool</p>
              <p className={`${cascading.textendsupply}`}>{roundToSignificant(propsdata?.poolShare,2)}%</p>
            </div>
          </div>





          <button className={`btn mt-4 ${cascading.connectwalletbtn}`} type='button' onClick={()=>{props?.supplyData()}}>Confirm Supply</button>







        </Modal.Body>
       </>

       :
       <>

       <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>You are creating a pool</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.balsec}`}>
            <p className={`${cascading.balvalue}`}>{`${propsdata?.fromToken?.symbol}/${propsdata?.toToken?.symbol}`} 
            <span className={`mx-1`}>
              <img src={fileObjectUrl(propsdata?.fromToken?.image)} alt='coin' className={`img-fluid ${cascading.coinimgstyle}`}/>
              <img src={fileObjectUrl(propsdata?.toToken?.image)} alt='coin' className={`img-fluid ${cascading.coinimgstyle}`}/>

              </span> 
              {/* <OverlayTrigger
              className={`${cascading.tooltip_style}`}
              key="left"
              placement="left"
              overlay={
                <Tooltip id={`tooltip-left`}>
                  Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                </Tooltip>
              }
            >
              <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
            </OverlayTrigger> */}
            </p>
            {/* <p className={`${cascading.coiname}`}>BNB/YUM Pool Tokens</p> */}

          </div>

          {/* <p className={`${cascading.listtext}`}>Output is estimated. If the price changes by more than 1% your Transactions will revert.</p> */}
          <div className={`${cascading.listsec}`}>
            <div className={`${cascading.listrow}`}>
              <p>{propsdata?.fromToken?.symbol} Deposited</p>
              <p className={`${cascading.textendsupply}`}><span className={`mx-1 `}>
                <img src={fileObjectUrl(propsdata?.fromToken?.image)} alt='coin' className={`img-fluid  ${cascading.coinimgstyle}`}/>
                </span>{roundToSignificant(propsdata?.fromValue,6)}</p>
            </div>
            <div className={`${cascading.listrow}`}>
              <p>{propsdata?.toToken?.symbol} Deposited</p>
              <p className={`${cascading.textendsupply}`}><span className={`mx-1 `}>
                <img src={fileObjectUrl(propsdata?.toToken?.image)} alt='coin' className={`img-fluid  ${cascading.coinimgstyle}`}/>
              </span>{roundToSignificant(propsdata?.toValue,6)}</p>
                {/* <OverlayTrigger
                className={`${cascading.tooltip_style}`}
                key="left"
                placement="left"
                overlay={
                  <Tooltip id={`tooltip-left`}>
                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                  </Tooltip>
                }
              >
                <Button className={`${cascading.tooltip_button}`}><FaRegQuestionCircle /></Button>
              </OverlayTrigger></p> */}
            </div>
            <div className={`${cascading.listrow}`}>
              <p>Rates</p>
              <div>
                <p className={`${cascading.textendsupply}`}>1{propsdata?.fromToken?.symbol} = {roundToSignificant(propsdata?.toTokenper,6)} {propsdata?.toToken?.symbol}</p>
                <p className={`${cascading.textendsupply}`}>1 {propsdata?.toToken?.symbol} = {roundToSignificant(propsdata?.fromTokenper,6)} {propsdata?.fromToken?.symbol}</p>
              </div>
            </div>
            <div className={`${cascading.listrow}`}>
              <p>Share of Pool</p>
              <p className={`${cascading.textendsupply}`}>{roundToSignificant(propsdata?.poolShare,6)}%</p>
            </div>
          </div>





          <button className={`btn mt-4 ${cascading.connectwalletbtn}`} type='button' onClick={()=>{props?.supplyData()}}>Create pool and Supply</button>







        </Modal.Body>
        </>
         }
      </Modal>
    </div>
  )
}

