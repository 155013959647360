import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/networkstyle.module.css';
import metaicon from '../assests/images/metamask.png';
import walleticon from '../assests/images/walletconnect.png';
import { CHAINS } from '../config/env'
import { setChainId, SwitchChain, SwitchChainS } from '../hooks/useWeb3';
import { IoMdClose } from "react-icons/io";
import { NETWORK_SWITCH } from '../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function Networkmodal(props) {
  const dispatch = useDispatch()

  const [netdetail, setNetdetail] = useState([{
    name: "Ethereum",
    img: require('../assests/images/eth.png'),
  },
  {
    name: "BNB Chain",
    img: require('../assests/images/bsc.png'),
  },
  {
    name: "Avalanche",
    img: require('../assests/images/Avalanche.png'),
  },
  {
    name: "Arbitrum",
    img: require('../assests/images/arb.png'),
  },
  {
    name: "zkSync",
    img: require('../assests/images/zkSync.png'),
  },
  ])

  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Network</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <p className={`${cascading.innerhead}`}>SELECT A NETWORK</p>
          <ul>
            {CHAINS.map((e, i) => (
              <li><button className={`${cascading.netbtn}`} onClick={async () => {
                let SwitchRes = await SwitchChainS(i);
                if (SwitchRes) {
                  setChainId(i);
                  props.onHide();
                  dispatch({
                    type: NETWORK_SWITCH,
                    payload: i.toString()
                  })
                }

              }} ><img src={e.IMAGE} className={`${cascading.netimg}`} alt='' /> {e.NAME}</button></li>

            ))}

          </ul>

        </Modal.Body>

      </Modal>
    </div>
  )
}

