import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/unstakestyle.module.css';
import toast, { Toaster } from 'react-hot-toast';

import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";

import bnbimg from '../assests/images/bnb.png'


import { REFERRER_ADDRESS } from '../config/env'

//hooks
import { Balance } from '../hooks/useErc20'
import { UnStake, Deposit } from '../hooks/useMasterChef'

//lib
import { isEmpty } from "../lib/isEmpty";
import { toFixedNumber } from '../lib/FixedNumber';
import {roundToSignificant } from '../hooks/useCommon';

//validation 
import { validateUnStakeFarms } from '../validation/stakeFarmsValidation'

export default function Unstakemodal(props) {


    // state 
    const [stakemodal, setStakemodal] = useState(true);
    const [data, setData] = useState();
    const [bal, setBal] = useState();
    const [unstakeValue, setUnstakeValue] = useState('');
    const [pending, setPending] = useState(false);
    const [errors, setErrors] = useState("");


    useEffect(() => {
        console.log("unstakemodal", props?.farms?.data, props)
        setData(props?.farms?.data)
        getBalance()
    })

    const getBalance = async () => {
        var bal = await Balance(data?.lpAddress)
        console.log("bal", bal)
        setBal(bal)
    }

    const Max = () => {
        setUnstakeValue(data?.StakedAmount)
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name == "unstakeValue") {
            var numbers = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
            if (!numbers.test(e.target.value) && e.target.value !== "") {
                return false
            }
            setUnstakeValue(value)
        }
    };

    const unstaking = async () => {
        let value = {
            unstakeValue: toFixedNumber(unstakeValue),
            StakedAmount: toFixedNumber(data?.StakedAmount)
        }
        let validation = validateUnStakeFarms(value)
        console.log("validation", validation)
        if (isEmpty(validation)) {
            setPending(true)
            setErrors('');
            console.log("parseFloat(unstakeValue*10**18).toFixed(0) : ", parseFloat(unstakeValue * 10 ** 18).toFixed(0))
            var res = await UnStake(data?.pid, toFixedNumber(parseFloat(unstakeValue * 10 ** 18).toFixed(0)))
            console.log("res", res)
            if (res == true) {
                toast.success(`Your ${data?.lpSymbol} earnings have also been unstaked to your wallet!`)
                props.onHide()
                setPending(false)
            }
            else {
                setPending(false)
                toast.error('Try Again!')
            }
        }
        else {
            setErrors(validation);
        }
    }







    return (
        <>
            <div className={`${cascading.networkmodalstyle}`}>
                <Modal centered show={stakemodal} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
                    <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
                        <h4 className={`${cascading.modaltitle}`}>Unstake LP tokens</h4>
                        <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />

                    </Modal.Header>
                    <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
                        <div className={`${cascading.inputsec} mb-4`}>
                            <div className={`${cascading.balancerow} ${cascading.balancerowcol}`}>
                                <p className={`${cascading.inputlabel} mb-0`}>Balance :</p>
                                <p className={`${cascading.inputlabel} ${cascading.inputlabelbreak}`}>{toFixedNumber(data?.StakedAmount)}</p>
                            </div>

                            <div className={`${cascading.inputsecrow}`}>
                                <div >
                                    <input
                                        type="text"
                                        autoComplete={"off"}
                                        className={`from-control`}
                                        id="tokenAval"
                                        aria-describedby="emailHelp"
                                        placeholder="0.0"
                                        value={isEmpty(unstakeValue) && isNaN(unstakeValue) ? 0.00 : toFixedNumber(unstakeValue)}
                                        name='unstakeValue'
                                        onChange={(e) => { onChange(e) }}
                                    />
                                </div>

                                <div className={` ${cascading.rightbtnsec} ${cascading.rightmaxbtn}`}>
                                    <button
                                        className={`btn ${cascading.currencybtn}`}
                                        onClick={() => { Max() }} > Max </button>

                                    <p className={`${cascading.inputlabel} ms-2 mb-0`} >{data?.lpSymbol}</p>


                                </div>

                            </div>

                        </div>
                        <p className={`${cascading.dangertext}`}>{errors.unstakeValue}</p>

                        <div className='d-flex align-items-center justify-content-between'>


                            <button className={`btn ${cascading.cancelbtn}`} type='button' onClick={props.onHide}>Cancel</button>

                            <button className={`btn ms-3 ${cascading.connectwalletbtn}`} type='button' onClick={() => { unstaking() }} disabled={unstakeValue == 0}>{pending == true ? "Confirming..." : "Confirm"}</button>

                        </div>
                        <div className='text-center mt-4'>
                            {/* <button className={`${cascading.declabel}`} target="_blank" >Get DSP-BNB LP</button> */}

                        </div>




                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

